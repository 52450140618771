import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IMessage } from "../types/message";

// Define the initial state using that type
const initialState: IMessage = {
  message: null,
  type: "",
  title: "",
  pretest_duration: null,
  user_id: null,
  order_id: null,
};

export const message = createSlice({
  name: "message",
  initialState,
  reducers: {
    setMessage: (state, action: PayloadAction<IMessage>) => {
      state.message = action.payload.message;
      state.type = action.payload.type;
      state.title = action.payload.title;
      state.pretest_duration = action.payload.pretest_duration;
      state.user_id = action.payload.user_id;
      state.order_id = action.payload.order_id;
    },
    clearMessage: (state) => {
      state.message = "";
      state.type = "";
      state.title = "";
      state.pretest_duration = "";
      state.user_id = "";
      state.order_id = "";
    },
  },
});

export const { setMessage, clearMessage } = message.actions;

export default message.reducer;
