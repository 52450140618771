import React from "react";

// types and services
// import { useAppSelector } from "../../../store/hooks";

// constants
import applied from "../../../assets/images/icons/applied.svg";
import schedule from "../../../assets/images/icons/schedule.svg";
import pending from "../../../assets/images/icons/pending.svg";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { get_total_applied } from "../../../services/api/applicatns";

const StatsCandidates: React.FC = () => {
  const dispatch = useAppDispatch();
  const total_applied = useAppSelector(
    ({ applicants }) => applicants.total_applied
  );

  const onLoad = React.useCallback(() => {
    get_total_applied(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="font-abel flex justify-between flex-wrap mt-2 mb-5">
      <div className="text-center bg-cardBg1 bg-cover bg-no-repeat pt-4 pb-4 mb-2 md:w-[32%] lg:w-[32%] w-[98%]">
        <div className="flex items-center justify-center mb-3">
          <img src={applied} alt="icon" />
          <p className="md:text-[22px] text-[16px] ml-3">Applied Jobs</p>
        </div>
        <p className="md:text-[60px] md:leading-10 leading-6 text-[30px] mb-3">
          {total_applied}
        </p>
      </div>
      <div className="text-center bg-cardBg2 bg-cover bg-no-repeat pt-4 pb-4 mb-2 md:w-[32%] lg:w-[32%] w-[98%]">
        <div className="flex items-center justify-center mb-3">
          <img src={schedule} alt="icon" />
          <p className="md:text-[22px] text-[16px] ml-3">Scheduled Interview</p>
        </div>
        <p className="md:text-[60px] md:leading-10 leading-6 text-[30px] mb-3">
          0
        </p>
      </div>
      <div className="text-center bg-cardBg3 bg-cover bg-no-repeat pt-4 pb-4 mb-2 md:w-[32%] lg:w-[32%] w-[98%]">
        <div className="flex items-center justify-center mb-3">
          <img src={pending} alt="icon" />
          <p className="md:text-[22px] text-[16px] ml-3">Pending</p>
        </div>
        <p className="md:text-[60px] md:leading-10 leading-6 text-[30px] mb-3">
          0
        </p>
      </div>
    </div>
  );
};

export default StatsCandidates;
