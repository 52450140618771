import React from "react";

// components
import { Modal } from "antd";

// constants
import alert from "../../../assets/images/alert/thanks-you.svg";
import error from "../../../assets/images/alert/error.svg";

// state
import { clearMessage } from "../../../store/slices/message";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import PretestConsent from "./PretestConsent";

type ISweetAlert = {
  visible: boolean;
  button_name?: string;
  onSubmit?: () => void;
};

const SweetAlert: React.FC<ISweetAlert> = ({
  visible,
  button_name,
  onSubmit,
}) => {
  const dispatch = useAppDispatch();
  const message = useAppSelector(({ message }) => message.message);
  const title = useAppSelector(({ message }) => message.title);
  const type = useAppSelector(({ message }) => message.type);
  const pretest_duration = useAppSelector(
    ({ message }) => message.pretest_duration
  );
  const user_id = useAppSelector(({ message }) => message.user_id);
  const order_id = useAppSelector(({ message }) => message.order_id);

  return (
    <Modal open={visible} centered footer={null} closable={false}>
      <div className="w-full flex flex-col justify-center items-center">
        <p className="mt-5 text-[35px] font-abel text-[#4E4E4E]">{title}</p>
        <div className="md:h-[150px] h-[150px] md:w-[150px] w-[150px]">
          <img
            src={type === "success" ? alert : error}
            alt="alert"
            className="w-full h-full"
          />
        </div>
        <p className="text-[20px] text-center font-abel text-[#4E4E4E] mb-5 pb-4">
          {message}
        </p>
        {message === "We will be in contact shortly." ? (
          <div></div>
        ) : (
          <div>
            <PretestConsent
              pretest_duration={pretest_duration}
              user_id={user_id}
              order_id={order_id}
            />
          </div>
        )}
        <button
          type="button"
          className="font-abel bg-[#ffffff] rounded text-[#243677] border border-[#243677] hover:bg-[#CDD8FE] hover:text-[#243677] cursor-pointer text-[16px] px-7 py-2"
          onClick={() => {
            dispatch(clearMessage());
            onSubmit?.();
          }}
        >
          {button_name || "Back to home"}
        </button>
      </div>
    </Modal>
  );
};

export default SweetAlert;
