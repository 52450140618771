import React from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

// theme
import ActionButton from "../../../components/ui/links/ActionButton";
import { Skeleton } from "antd";

// state
import { get_job, apply } from "../../../services/api/jobs";

// router
import { useNavigate, useParams } from "react-router-dom";
import SweetAlert from "../../../components/ui/sweet_alert";

type IViewOfferType = {};

const Job: React.FC<IViewOfferType> = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const loading = useAppSelector(({ loading }) => loading.loading);
  const apply_loader = useAppSelector(({ loading }) => loading.update);
  const order = useAppSelector(({ jobs }) => jobs.job);
  const message = useAppSelector(({ message }) => message.message);

  const onLoad = React.useCallback(() => {
    if (id) {
      get_job(dispatch, id);
    }
  }, [dispatch, id]);

  const onScroll = React.useCallback(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    onLoad();
    onScroll();
  }, [onLoad, onScroll]);

  const description = order?.description ? (order?.description as string) : "";

  return (
    <div className="items-center md:-w-6/12 flex flex-col">
      <header className="w-full bg-job-single-img">
        <div className="bg-job-single-opacity px-7 py-[6rem] text-[white]">
          {!loading ? (
            <>
              <h3 className="capitalize text-4xl font-abel font-[900]">
                {order?.position?.name}
              </h3>
              <span className="capitalize font-abel text-2xl">
                {order?.state?.name} {order?.state?.country?.name}
              </span>
            </>
          ) : (
            <Skeleton paragraph={{ rows: 3 }} />
          )}
        </div>
      </header>
      <section className="relative w-[95%] top-[-30px] px-4 pt-[2rem] pb-[4rem] rounded-xl bg-[#F5F5F5]">
        <div className="w-full">
          {!loading ? (
            <div
              className="w-[100%] max-w-full font-abel wysiwyg"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          ) : (
            <Skeleton paragraph={{ rows: 20 }} />
          )}

          {order?.availability === "1" && (
            <div className="w-full text-right mt-5 flex justify-end">
              <ActionButton
                title="Apply"
                loading={apply_loader}
                onHandlePress={() => apply(dispatch, order.slug)}
                className="w-[22%] text-[white] rounded-lg px-5 py-3"
              />
            </div>
          )}
          {order?.availability !== "1" && (
            <div className=' className="w-full text-right mt-5 text-[20px] font-semibold"'>
              This opportunity has been closed
            </div>
          )}
        </div>
      </section>
      <SweetAlert
        visible={message !== null ? true : false}
        onSubmit={() => navigate(-1)}
      />
    </div>
  );
};

export default Job;
