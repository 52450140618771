import React from "react";
import { Checkbox, CheckboxProps } from "antd";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { iPretestConsent } from "../../../store/types/jobs";
import SubmitButton from "../../elements/forms/SubmitButton";
import { useAppSelector } from "../../../store/hooks";
import axiosInstance from "../../../utility/axiosInstance";

type iPretestConsentData = {
  onPress?: (data: iPretestConsent) => void;
  pretest_duration: string | any;
  user_id: string | any;
  order_id: string | any;
};

const PretestConsent: React.FC<iPretestConsentData> = ({
  user_id,
  order_id,
  pretest_duration,
}) => {
  const navigate = useNavigate();
  const loading = useAppSelector(({ loading }) => loading.loading);
  // const order = useAppSelector(({ jobs }) => jobs.job);
  const [data] = React.useState<iPretestConsent>({
    accept: true,
    pretest_duration: pretest_duration,
    user_id: user_id,
    order_id: order_id,
    // user_id: "9edaa5e4-f7b7-4847-83e3-c173b5084c65",
    // order_id: "21404266-3741-480a-965e-3daf27be9487",
  });

  // const onCheck = () => {
  //   dataSet((prev) => ({ ...prev, check: !data.accept }));
  //   dataErrSet((prev) => ({ ...prev, check: "" }));
  // };
  const onChange: CheckboxProps["onChange"] = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };

  const onSubmit = async () => {
    const { user_id, order_id } = data;

    try {
      const response = await axiosInstance.post(
        "/accept-pretest",
        { user_id, order_id, pretest_duration },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      toast.success("Pretest link sent successfully");
      navigate("/");
      console.log("Success:", response.data);
    } catch (error) {
      toast.error("No pretest found for this job order.");
      console.error("Error:", error);
    }
  };

  return (
    <div>
      <div className="font-abel ">
        <div className="flex justify-center items-center font-abel max-w-[500px] py-2 px-2 bg-[#f2f6fc]">
          <Checkbox onChange={onChange} className="mt-0" />
          <span className="text-[15px] text-[#000000] ml-3 font-abel leading-5 cursor-pointer">
            To proceed with the selection process, please click on the checkbox
            to receive the Pretest and Assessment questions via email.
          </span>
        </div>
        <div className="mt-2 text-center">
          <p className="text-[16px] text-[#000000] font-bold font-abel cursor-pointer">
            Completing this step is essential for advancing in the recruitment
            process.
          </p>
        </div>
        <div className="max-w-[150px] mx-auto mb-1 mt-3">
          <SubmitButton
            loading={loading}
            className="my-2 border"
            title="I Accept"
            onSubmitHandler={onSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default PretestConsent;
