import React from "react";

// types
import { JobOrderType } from "../../../store/types/jobs";
import LinkButton from "../links/LinkButton";
import db from "../../../constants/database";

// constants
import { get_month_and_day } from "../../../services/formatter/date";

type IJobCardType = {
  key: string;
  item: JobOrderType;
};

const JobCard: React.FC<IJobCardType> = ({ item }) => {
  const avatar = item?.user?.profile?.avatar || db.icon_black;
  const date = get_month_and_day(item?.created_at);

  return (
    <div className="flex justify-between px-[5px] items-center py-4 border border-[#D0D0D0] md:px-6 my-3">
      <div className="flex items-center w-10/12">
        <div className="m-1 md:p-1 w-3/12 md:w-2/12">
          <div className="md:border rounded-full md:h-[102px] md:w-[102px] md:flex md:items-center md:justify-center">
            <img
              src={avatar}
              alt="Company Avatar"
              className="border md:border-2 h-[70px] w-[70px] rounded-full md:h-[96px] md:w-[96px] object-fill"
            />
          </div>
        </div>
        <div className="px-[4px] md:px-2 w-8/12 md:w-8/12">
          <h3 className="text-lg md:text-2xl font-[700] text-[#100F0F] capitalize">
            {item?.position?.name}
          </h3>
          <div className="flex flex-col md:flex-row">
            <span className="text-sm md:text-lg mx-[2px] md:mr-4 text-[#4E4E4E] capitalize">
              {item?.state?.name}, {item?.state?.country?.name} -{" "}
              {item?.job_type?.name?.toUpperCase()}
            </span>
          </div>
        </div>
      </div>

      <div className="text-center w-3/12 flex flex-col justify-center items-center">
        <LinkButton
          title="View JD"
          path={`/job/${item.slug}`}
          className="px-[15px] py-[8px]"
        />
        <span className="text-[#243677] text-[15px] md:block mt-4">{date}</span>
      </div>
    </div>
  );
};

export default JobCard;
